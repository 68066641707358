<template>
    <MainContainer>
        <div class="locations">
            <h2>Standorte</h2>
            <b-row class="my-4">
                <b-col md="9">
                    <b-row>
                        <b-col cols="12">
                            <h5>Filter</h5>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <!--<h6>VHS</h6>
                            <CheckboxSelect
                                :options="vhsSelect"
                                v-model="filter.vhs"
                                :size="size"
                            />-->
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-button :size="size" @click="applyFilter"
                                >Filter anwenden</b-button
                            >
                        </b-col>
                    </b-row>
                </b-col>
                <b-col md="3">
                    <h5>Funktionen</h5>
                    <b-button
                        variant="outline-primary"
                        size="sm"
                        @click="openModificationModal({ mode: 'create' })"
                        >Neuer Standort</b-button
                    >
                    <b-button
                        class="mx-1"
                        variant="outline-danger"
                        size="sm"
                        :disabled="!(selected.length > 0)"
                        @click="openDeleteModal({ multi: true })"
                        >Auswahl löschen</b-button
                    >
                    <hr />
                    <h6>Export</h6>
                    <TableExport
                        :busy="busy"
                        :dataAvailable="dataAvailable"
                        @exportExcel="exportExcel"
                        @exportPDF="exportPDF"
                    />
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12" class="my-4">
                    <TableFilter v-model="filter.pattern" />
                </b-col>
            </b-row>
            <b-table
                :items="records"
                :fields="currentSelectedFields"
                :filter="filter.pattern"
                :busy="busy.data"
                :small="size == 'sm'"
                select-mode="multi"
                sort-by="title"
                ref="selectableTable"
                empty-text="Keine Daten vorhanden"
                empty-filtered-text="Für den gewählten Filter wurden keine Daten gefunden"
                foot-clone
                no-footer-sorting
                selectable
                bordered
                striped
                responsive
                show-empty
                @row-selected="onRowSelected"
            >
                <template #thead-top="{ columns }">
                    <b-tr>
                        <b-td>
                            <p class="small m-0">
                                Gesamt: {{ records.length }}
                            </p>
                            <p class="small m-0">
                                Gewählt:
                                <template v-if="selected.length > 0"
                                    >{{ selected.length }}
                                </template>
                                <template v-else>-</template>
                            </p>
                        </b-td>
                        <b-td :colspan="currentRecordFields.length"></b-td>
                        <b-td class="text-right"
                            ><b-dropdown
                                variant="outline-secondary"
                                text="Spaltenauswahl"
                                :size="size"
                            >
                                <b-dropdown-form>
                                    <b-form-checkbox-group
                                        v-model="selectedFields"
                                        :options="recordFields"
                                        value-field="key"
                                        text-field="label"
                                    ></b-form-checkbox-group>
                                </b-dropdown-form>
                            </b-dropdown>
                        </b-td>
                    </b-tr>
                </template>

                <template #table-busy>
                    <div class="text-center text-secondary my-2">
                        <b-spinner class="mr-1 align-middle" small></b-spinner>
                        <strong>Daten werden geladen...</strong>
                    </div>
                </template>

                <template #head(__selected)>
                    <input
                        type="checkbox"
                        v-model="selectAll"
                        :true-value="true"
                        :false-value="false"
                        @change="toggleAllSelectedRows"
                    />
                </template>

                <template #cell(__selected)="{ rowSelected }">
                    <input
                        type="checkbox"
                        :checked="rowSelected"
                        :true-value="true"
                        :false-value="false"
                        disabled
                    />
                </template>

                <template #cell(type)="data">
                    <template v-if="data.value.includes('cambridge_subcentre')">
                        <p>Cambridge Subcentre</p>
                    </template>
                    <template
                        v-if="data.value.includes('cambridge_prepcentre')"
                    >
                        <p>Cambridge Prepcentre</p>
                    </template>
                </template>

                <template #cell(cambridge_rel_sub_prep)="data">
                    <template v-if="data.value">
                        {{ locationName(data.value) }}
                    </template>
                </template>

                <template #cell(__actions)="data">
                    <b-button
                        variant="outline-primary"
                        size="sm"
                        class="mx-1"
                        @click="
                            openModificationModal({
                                record: data.item,
                                mode: 'edit'
                            })
                        "
                        >Bearbeiten</b-button
                    >
                    <b-button
                        variant="outline-danger"
                        size="sm"
                        class="mx-1"
                        @click="openDeleteModal({ record: data.item })"
                        >Löschen</b-button
                    >
                </template>
            </b-table>

            <EditModal
                :busy="busy.mutation"
                :mode="modificationMode"
                size="xl"
                @ok="handleModification"
            >
                <b-tabs pills card vertical>
                    <b-tab title="Stammdaten" active>
                        <FormGroupBuilder
                            :fields="ioFields"
                            :data="modificationRecord"
                        />
                    </b-tab>
                    <b-tab title="Kontaktdaten">
                        <PersonContactView :data="modificationRecord" />
                    </b-tab>
                    <b-tab title="Zugewiesene Kontakte" :disabled="true">
                    </b-tab>
                    <b-tab
                        v-if="showCambridgeIoFields"
                        title="Cambridge Subcentre"
                    >
                        <FormGroupBuilder
                            :fields="cambridgeIoFields"
                            :data="modificationRecord"
                        />
                    </b-tab>
                    <b-tab
                        v-if="showCambridgePrepcentreIoFields"
                        title="Cambridge Prepcentre"
                    >
                        <FormGroupBuilder
                            :fields="cambridgePrepcentreIoFields"
                            :data="modificationRecord"
                        />
                    </b-tab>
                </b-tabs>
            </EditModal>

            <DeleteModal
                :mode="modificationMode"
                :record="modificationRecord"
                :fields="currentRecordFields"
                :selected="selected"
                :busy="busy.mutation"
                @ok="deleteRecord"
            >
            </DeleteModal>
        </div>
    </MainContainer>
</template>

<script>
import MainContainer from "@/components/MainContainer/MainContainer";
import ModelView from "@/mixins/ModelView/ModelView";
import Size from "@/mixins/Size/Size";
import FormGroupBuilder from "@/components/FormGroupBuilder/FormGroupBuilder";
import CheckboxSelect from "@/components/CheckboxSelect/CheckboxSelect";
import TableExport from "@/components/TableExport/TableExport";
import TableFilter from "@/components/TableFilter/TableFilter";
import EditModal from "@/components/EditModal/EditModal";
import DeleteModal from "@/components/DeleteModal/DeleteModal";
import PersonContactView from "@/components/PersonContactView/PersonContactView";
import { mapActions, mapGetters } from "vuex";

/*
TODO
Fehlende Felder:
- Kontaktdaten
- Ansprechpartner (Zuweisung Kontakte)
*/

export default {
    name: "Locations",
    components: {
        MainContainer,
        FormGroupBuilder,
        TableExport,
        TableFilter,
        EditModal,
        DeleteModal,
        CheckboxSelect,
        PersonContactView
    },
    mixins: [ModelView, Size],
    data() {
        return {
            filter: {
                pattern: null,
                vhs: []
            },
            fields: [
                {
                    key: "__selected",
                    label: "",
                    class: "text-center",
                    static: true,
                    position: "prepend"
                },
                {
                    key: "id",
                    label: "ID",
                    sortable: true
                },
                {
                    key: "name",
                    label: "Name",
                    sortable: true
                },
                {
                    key: "address",
                    label: "Anschrift",
                    sortable: true
                },
                {
                    key: "zip",
                    label: "PLZ",
                    sortable: true
                },
                {
                    key: "location",
                    label: "Ort",
                    sortable: true
                },
                {
                    key: "type",
                    label: "Typ",
                    sortable: true
                },
                {
                    key: "description",
                    label: "Info",
                    sortable: true
                },
                {
                    key: "cambridge_centrenr",
                    label: "Center Nr.",
                    sortable: true
                },
                {
                    key: "cambridge_subcentre",
                    label: "Subcentre",
                    sortable: true
                },
                {
                    key: "cambridge_prep_id",
                    label: "Preparation centre ID",
                    sortable: true
                },
                {
                    key: "cambridge_prep_",
                    label: "Name",
                    sortable: true
                },
                {
                    key: "cambridge_prep_short_name",
                    label: "Short name",
                    sortable: true
                },
                {
                    key: "cambridge_prep_type_of_funding",
                    label: "Type of funding",
                    sortable: true
                },
                {
                    key: "cambridge_prep_contact",
                    label: "Contact name",
                    sortable: true
                },
                {
                    key: "cambridge_prep_contact_mail",
                    label: "Contact mail",
                    sortable: true
                },
                {
                    key: "cambridge_prep_contact_number",
                    label: "Contact number",
                    sortable: true
                },
                {
                    key: "cambridge_prep_country_code",
                    label: "Country code",
                    sortable: true
                },
                {
                    key: "cambridge_prep_area_code",
                    label: "Area code",
                    sortable: true
                },
                {
                    key: "cambridge_prep_access_status",
                    label: "Access status"
                },
                {
                    key: "cambridge_rel_sub_prep",
                    label: "Zugewiesenes Subcenter"
                },
                {
                    key: "__actions",
                    label: "Aktionen",
                    class: "text-right",
                    static: true,
                    position: "append"
                }
            ],
            ioFields: {
                id: {
                    key: "id",
                    label: "ID",
                    disabled: true
                },
                token: {
                    key: "token",
                    label: "Kundennummer"
                },
                name: {
                    key: "name",
                    label: "Name",
                    description: "Name der Einrichtung/Institution/Firma"
                },
                address: {
                    key: "address",
                    label: "Anschrift"
                },
                zip: {
                    key: "zip",
                    label: "PLZ"
                },
                location: {
                    key: "location",
                    label: "Ort"
                },
                type: {
                    key: "type",
                    label: "Typ",
                    type: "checkboxselect",
                    options: [
                        {
                            value: "cambridge_subcentre",
                            text: "Cambridge Subcentre"
                        },
                        {
                            value: "cambridge_prepcentre",
                            text: "Cambridge Prepcentre"
                        }
                    ]
                },
                description: {
                    key: "description",
                    label: "Info",
                    type: "textarea"
                }
            },
            cambridgeIoFields: {
                cambridge_centrenr: {
                    key: "cambridge_centrenr",
                    label: "Center Nr."
                },
                cambridge_subcentre: {
                    key: "cambridge_subcentre",
                    label: "Subcentre"
                }
            },
            cambridgePrepcentreIoFields: {
                cambridge_prep_id: {
                    key: "cambridge_prep_id",
                    label: "Preparation centre ID"
                },
                cambridge_prep_name: {
                    key: "cambridge_prep_name",
                    label: "Name"
                },
                cambridge_prep_short_name: {
                    key: "cambridge_prep_short_name",
                    label: "Short name"
                },
                cambridge_prep_type_of_funding: {
                    key: "cambridge_prep_type_of_funding",
                    label: "Type of funding"
                },
                cambridge_prep_contact: {
                    key: "cambridge_prep_contact",
                    label: "Contact name"
                },
                cambridge_prep_contact_mail: {
                    key: "cambridge_prep_contact_mail",
                    label: "Contact mail"
                },
                cambridge_prep_contact_number: {
                    key: "cambridge_prep_contact_number",
                    label: "Contact number"
                },
                cambridge_prep_country_code: {
                    key: "cambridge_prep_country_code",
                    label: "Country code"
                },
                cambridge_prep_area_code: {
                    key: "cambridge_prep_area_code",
                    label: "Area code"
                },
                cambridge_prep_access_status: {
                    key: "cambridge_prep_access_status",
                    label: "Access status"
                },
                cambridge_rel_sub_prep: {
                    key: "cambridge_rel_sub_prep",
                    label: "Zugewiesenes Subcenter",
                    type: "checkboxselect"
                }
            },
            showCambridgeIoFields: false,
            showCambridgePrepcentreIoFields: false,
            selectedFields: [
                "id",
                "name",
                "description",
                "token",
                "zip",
                "address",
                "location",
                "type"
            ],
            actions: {
                create: "locations/createData",
                edit: "locations/editData",
                delete: "locations/deleteData",
                deleteMulti: "locations/deleteMultipleData"
            }
        };
    },
    computed: {
        ...mapGetters({
            busy: "locations/getBusy",
            records: "locations/getData",
            locationName: "locations/getName"
        }),
        dataAvailable() {
            return this.records.length > 0;
        }
    },
    created() {
        this.fetch({ filter: this.filter });
        this.fetchSubcentre();
    },
    watch: {
        "modificationRecord.type": {
            handler(val) {
                if (val.includes("cambridge_subcentre")) {
                    this.showCambridgeIoFields = true;
                } else {
                    this.showCambridgeIoFields = false;
                }

                if (val.includes("cambridge_prepcentre")) {
                    this.showCambridgePrepcentreIoFields = true;
                } else {
                    this.showCambridgePrepcentreIoFields = false;
                }
            }
        }
    },
    methods: {
        ...mapActions({
            fetch: "locations/fetchData"
        }),
        async fetchSubcentre() {
            await this.$store.dispatch("examsCambridgeSubcentre/fetchData");
            const selectData = this.$store.getters[
                "examsCambridgeSubcentre/getSelectData"
            ];
            this.cambridgePrepcentreIoFields.cambridge_rel_sub_prep.options = selectData;
        },
        async exportExcel(format) {
            this.$store.dispatch("locations/exportExcel", {
                format,
                filter: this.filter,
                selectedFields: this.selectedFields,
                selectedData: this._discardSelectedData(this.selected, "id")
            });
        },
        async exportPDF(orientation = "P") {
            this.$store.dispatch("locations/exportPDF", {
                orientation,
                filter: this.filter,
                selectedFields: this.selectedFields,
                selectedData: this._discardSelectedData(this.selected, "id")
            });
        },
        applyFilter() {
            this.fetch({ filter: this.filter });
        }
    }
};
</script>
