var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MainContainer',[_c('div',{staticClass:"locations"},[_c('h2',[_vm._v("Standorte")]),_c('b-row',{staticClass:"my-4"},[_c('b-col',{attrs:{"md":"9"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h5',[_vm._v("Filter")])])],1),_c('b-row',[_c('b-col')],1),_c('b-row',[_c('b-col',[_c('b-button',{attrs:{"size":_vm.size},on:{"click":_vm.applyFilter}},[_vm._v("Filter anwenden")])],1)],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('h5',[_vm._v("Funktionen")]),_c('b-button',{attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function($event){return _vm.openModificationModal({ mode: 'create' })}}},[_vm._v("Neuer Standort")]),_c('b-button',{staticClass:"mx-1",attrs:{"variant":"outline-danger","size":"sm","disabled":!(_vm.selected.length > 0)},on:{"click":function($event){return _vm.openDeleteModal({ multi: true })}}},[_vm._v("Auswahl löschen")]),_c('hr'),_c('h6',[_vm._v("Export")]),_c('TableExport',{attrs:{"busy":_vm.busy,"dataAvailable":_vm.dataAvailable},on:{"exportExcel":_vm.exportExcel,"exportPDF":_vm.exportPDF}})],1)],1),_c('b-row',[_c('b-col',{staticClass:"my-4",attrs:{"md":"12"}},[_c('TableFilter',{model:{value:(_vm.filter.pattern),callback:function ($$v) {_vm.$set(_vm.filter, "pattern", $$v)},expression:"filter.pattern"}})],1)],1),_c('b-table',{ref:"selectableTable",attrs:{"items":_vm.records,"fields":_vm.currentSelectedFields,"filter":_vm.filter.pattern,"busy":_vm.busy.data,"small":_vm.size == 'sm',"select-mode":"multi","sort-by":"title","empty-text":"Keine Daten vorhanden","empty-filtered-text":"Für den gewählten Filter wurden keine Daten gefunden","foot-clone":"","no-footer-sorting":"","selectable":"","bordered":"","striped":"","responsive":"","show-empty":""},on:{"row-selected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"thead-top",fn:function(ref){
var columns = ref.columns;
return [_c('b-tr',[_c('b-td',[_c('p',{staticClass:"small m-0"},[_vm._v(" Gesamt: "+_vm._s(_vm.records.length)+" ")]),_c('p',{staticClass:"small m-0"},[_vm._v(" Gewählt: "),(_vm.selected.length > 0)?[_vm._v(_vm._s(_vm.selected.length)+" ")]:[_vm._v("-")]],2)]),_c('b-td',{attrs:{"colspan":_vm.currentRecordFields.length}}),_c('b-td',{staticClass:"text-right"},[_c('b-dropdown',{attrs:{"variant":"outline-secondary","text":"Spaltenauswahl","size":_vm.size}},[_c('b-dropdown-form',[_c('b-form-checkbox-group',{attrs:{"options":_vm.recordFields,"value-field":"key","text-field":"label"},model:{value:(_vm.selectedFields),callback:function ($$v) {_vm.selectedFields=$$v},expression:"selectedFields"}})],1)],1)],1)],1)]}},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-secondary my-2"},[_c('b-spinner',{staticClass:"mr-1 align-middle",attrs:{"small":""}}),_c('strong',[_vm._v("Daten werden geladen...")])],1)]},proxy:true},{key:"head(__selected)",fn:function(){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectAll),expression:"selectAll"}],attrs:{"type":"checkbox","true-value":true,"false-value":false},domProps:{"checked":Array.isArray(_vm.selectAll)?_vm._i(_vm.selectAll,null)>-1:(_vm.selectAll)},on:{"change":[function($event){var $$a=_vm.selectAll,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selectAll=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selectAll=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selectAll=$$c}},_vm.toggleAllSelectedRows]}})]},proxy:true},{key:"cell(__selected)",fn:function(ref){
var rowSelected = ref.rowSelected;
return [_c('input',{attrs:{"type":"checkbox","true-value":true,"false-value":false,"disabled":""},domProps:{"checked":rowSelected}})]}},{key:"cell(type)",fn:function(data){return [(data.value.includes('cambridge_subcentre'))?[_c('p',[_vm._v("Cambridge Subcentre")])]:_vm._e(),(data.value.includes('cambridge_prepcentre'))?[_c('p',[_vm._v("Cambridge Prepcentre")])]:_vm._e()]}},{key:"cell(cambridge_rel_sub_prep)",fn:function(data){return [(data.value)?[_vm._v(" "+_vm._s(_vm.locationName(data.value))+" ")]:_vm._e()]}},{key:"cell(__actions)",fn:function(data){return [_c('b-button',{staticClass:"mx-1",attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function($event){return _vm.openModificationModal({
                            record: data.item,
                            mode: 'edit'
                        })}}},[_vm._v("Bearbeiten")]),_c('b-button',{staticClass:"mx-1",attrs:{"variant":"outline-danger","size":"sm"},on:{"click":function($event){return _vm.openDeleteModal({ record: data.item })}}},[_vm._v("Löschen")])]}}])}),_c('EditModal',{attrs:{"busy":_vm.busy.mutation,"mode":_vm.modificationMode,"size":"xl"},on:{"ok":_vm.handleModification}},[_c('b-tabs',{attrs:{"pills":"","card":"","vertical":""}},[_c('b-tab',{attrs:{"title":"Stammdaten","active":""}},[_c('FormGroupBuilder',{attrs:{"fields":_vm.ioFields,"data":_vm.modificationRecord}})],1),_c('b-tab',{attrs:{"title":"Kontaktdaten"}},[_c('PersonContactView',{attrs:{"data":_vm.modificationRecord}})],1),_c('b-tab',{attrs:{"title":"Zugewiesene Kontakte","disabled":true}}),(_vm.showCambridgeIoFields)?_c('b-tab',{attrs:{"title":"Cambridge Subcentre"}},[_c('FormGroupBuilder',{attrs:{"fields":_vm.cambridgeIoFields,"data":_vm.modificationRecord}})],1):_vm._e(),(_vm.showCambridgePrepcentreIoFields)?_c('b-tab',{attrs:{"title":"Cambridge Prepcentre"}},[_c('FormGroupBuilder',{attrs:{"fields":_vm.cambridgePrepcentreIoFields,"data":_vm.modificationRecord}})],1):_vm._e()],1)],1),_c('DeleteModal',{attrs:{"mode":_vm.modificationMode,"record":_vm.modificationRecord,"fields":_vm.currentRecordFields,"selected":_vm.selected,"busy":_vm.busy.mutation},on:{"ok":_vm.deleteRecord}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }